import {relayPool, getPublicKey} from 'nostr-tools'

const privkey = "BE711479A0FC504E04545323CE88F89D456B804CE6948B8172637E02424A9560"
const pubkey = getPublicKey(privkey)
let relays = []
const pool = relayPool()
let ids = []
let testIndex
const testResultList = document.querySelector('#responseResult')
const runTestButton = document.querySelector('#responseTest')

export function setRelays(r) {
  relays = r
}

export async function initTest() {
  pool.setPrivateKey(privkey)
  
  pool.onEvent((event, context, relay) => {
    console.log(`got a relay with context ${context} from ${relay.url} which is already validated.`, event)
    const li = document.createElement('li')
    const delay = (event.tags.length && 
                   event.tags[0][0] === "t" && 
                   (new Date()).getTime() - event.tags[0][1]) || 
                   ""
    li.innerHTML = `response in <strong>${delay}</strong> ms from ${relay.url}`
    li.id = relay.url
    testResultList.appendChild(li);
    event.delay = delay
    event.url = relay.url
    testIndex ++
    testOne()
    ids.push(event)
  })

  pool.onNotice((message, relay) => {
    const li = document.createElement('li')
    li.innerHTML = `<strong>${message}</strong> from ${relay.url}`
    testResultList.appendChild(li);
    testIndex ++
    testOne()
  })

  await pool.subKey(pubkey)

  function reset() {testResultList.innerHTML = ""; ids = [] }

  function sortReays() {
    ids.sort((a,b) => a.delay - b.delay)
    ids.forEach((id, i) => {
      const node = document.getElementById(id.url)
      testResultList.insertBefore(node, testResultList.childNodes[i])
    })
  }

  async function testOne() {
    if (testIndex > 0) await pool.removeRelay(relays[testIndex - 1])
    if (testIndex >= relays.length) {
      sortReays()
      return
    }
    const url = relays[testIndex]
    await pool.addRelay(url, {read: true, write: true})
    const created = new Date().getTime()
    const content = {name: "test bot", about: "performance test", picture: ""}
    await pool.publish({
      pubkey,
      created_at: Math.round(created / 1000),
      kind: 0,
      content: JSON.stringify(content),
      tags: [["t", created, ""]]
    })
  }

  runTestButton.addEventListener('click', async e => {
    console.log('click')
    e.preventDefault()
    reset()
    testIndex = 0
    testOne()
  })

  testIndex = 0
  testOne()
}

