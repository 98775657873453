import {setRelays, initTest} from './responseTest'

const relayList = document.querySelector("tbody")
let currentRelays = {}, sortcol = '', sortdir = -1

const updated = document.querySelector('#updated')

updateRelays()
initTest()

setInterval(updateRelays, 30000)

function sortRelays(col) {
  const rows = [...relayList.childNodes]
  rows.sort((a, b) => {
    const aa = a.querySelector(`.${col}`).dataset.intval
    const bb = b.querySelector(`.${col}`).dataset.intval
    return sortdir * (parseInt(aa, 10) - parseInt(bb, 10))
  })
  rows.forEach((row, i) => {
    relayList.insertBefore(row, relayList.childNodes[i])
  })
}

function updateRelays() {
  postApi('getrelays', null, "GET", "application/json").then(relays => {
    Object.keys(relays).forEach(url => {
      const relay = relays[url]
      if (url in currentRelays) {
        const currentRow = currentRelays[url]
        currentRow.uptime.update(relay.uptime)
        currentRow.currentup.update(relay)
        currentRow.uppct.update(relay.uppct)
        currentRow.connected.update(relay.connected)
      } else {
        relay.url = url
        const tr = createRow(relay) 
        relayList.appendChild(tr)
      }
    })
    updated.className = "hidden"
    setTimeout(() => {
      updated.className='visible'
      updated.textContent = (new Date()).toISOString().slice(0,19).replace('T', ' ')
    }, 500)
    setRelays(Object.keys(relays))
  })
}

function createRow(relay) {
  const tr = document.createElement('tr')
  tr.className = "relay"
  const url = new td(tr, 'url', relay.url)
  url.el.setAttribute('data-label', 'url')
  const nips = new td(tr, 'nips', relay.nips)
  nips.el.setAttribute('data-label', 'nips')
  const created = new td(tr, 'created', (new Date(relay.created)).toISOString().slice(0,10))
  created.el.setAttribute('data-label', 'Registered')
  const uptime = new td(tr, 'uptime', relay.uptime, msToDHM)
  uptime.el.setAttribute('data-label', 'Total up time')
  uptime.el.setAttribute('data-intval', relay.uptime)
  const currentup = new td(tr, 'currentup', relay, relay => {
    const intval = (new Date()).getTime() - (relay.lastdown || relay.created)
    currentup.el.setAttribute('data-intval', intval)
    if (relay.connected) return msToDHM(intval)
    else return ''
  })
  currentup.el.setAttribute('data-label', 'Current up time')
  const uppct = new td(tr, 'uppct', relay.uppct)
  uppct.el.setAttribute('data-label', 'up %')
  uppct.el.setAttribute('data-intval', relay.uppct)
  const connected = new td(tr, 'connected', relay.connected, c => {
    const txt =  c ? 'online' : 'offline' 
    connected.el.className = txt
    return txt
  })
  connected.el.setAttribute('data-label', 'Status')
  currentRelays[relay.url] = {tr, uptime, currentup, uppct, connected}
  return tr
}

// https://stackoverflow.com/a/8528531/1949741
function msToDHM(t){
  var cd = 24 * 60 * 60 * 1000,
      ch = 60 * 60 * 1000,
      d = Math.floor(t / cd),
      h = Math.floor( (t - d * cd) / ch),
      m = Math.round( (t - d * cd - h * ch) / 60000),
      pad = function(n){ return n < 10 ? '0' + n : n; };
  if( m === 60 ){
    h++;
    m = 0;
  }
  if( h === 24 ){
    d++;
    h = 0;
  }
  return [d, pad(h), pad(m)].join(':');
}

function postApi(command, data, method, contenttype) {
  method = method || 'POST'
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const endpoint = `https://${window.location.hostname}:3101/${command}`
    xhr.open(method, endpoint, true);

    if (contenttype !== 'none') xhr.setRequestHeader("Content-Type", contenttype || "application/json")

    xhr.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        try {
          resolve(JSON.parse(xhr.responseText))
        } catch (e) {
          reject(e.message)
        }
      }
      if (this.readyState === XMLHttpRequest.DONE && this.status === 403) {
        const response = JSON.parse(this.responseText)
        reject(new Error(response.err))
      }
    };

    xhr.onerror = reject

    if (method === 'GET') xhr.send(JSON.stringify(data))
    else if (contenttype) xhr.send(JSON.stringify(data))
    else xhr.send(data)
  });
}

class td {
  constructor(tr, identifier, rawValue, update) {
    this.el = document.createElement('td')
    this.uptateFn = update
    this.update = (raw) => {
      if (this.uptateFn) this.el.textContent = this.uptateFn(raw)
      else this.el.textContent = raw
    }
    setTimeout(() => this.update(rawValue), 0)
    this.el.className = identifier
    tr.appendChild(this.el)
  }
}

const sortable = document.querySelectorAll('.sortable')

for (var s = 0; s < sortable.length; s++) {
  sortable[s].addEventListener('click', e => {
    if (sortcol === e.target.dataset.sort) sortdir = sortdir * -1
    sortcol = e.target.dataset.sort
    sortRelays(sortcol)
    for (var c=0; c<sortable.length; c++) {
      const icon = sortable[c].querySelector('span')
      if (sortable[c] === e.target) {icon.className = ""; icon.textContent = sortdir === 1 ? '⏶' : '⏷'}
      else {icon.className = "notsort"; icon.textContent = sortdir === 1 ? '⏷' : '⏶'}
    }
  })
}
